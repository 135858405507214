import { useState, useEffect } from "react";

const useFetch = (url, filters = {}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Get locale from localStorage or use the default
  const locale = localStorage.getItem("cf_language") || "de-CH";

  // Build query parameters dynamically
  const params = new URLSearchParams({
    locale: locale,
    "pagination[limit]": -1,
    ...filters, // Add any additional filters passed as arguments
  });

  const reqUrl = `${process.env.REACT_APP_STRAPI_URL}/api${url}?${params.toString()}`;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const res = await fetch(reqUrl);
        if (!res.ok) throw new Error(`HTTP error! status: ${res.status}`);
        const json = await res.json();
        setData(json);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [reqUrl]);

  return { loading, error, data };
};

export default useFetch;
