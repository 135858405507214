import React from 'react';
import {Helmet} from "react-helmet";
import SiteHeader from "../components/SiteHeader";
import useFetch from "../hooks/useFetch";
import LoginTabs from "./LoginTabs";

const LoginPage = (properties) => {
    const { loading, error, data } = useFetch("/login");

    if (error) return <p>Error</p>;
    if (loading)
        return (
            <div className="d-flex justify-content-center mt-5 pt-lg-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );

    let content = data.data.attributes;

    return (
        <div>
            <Helmet>
                <meta name="description" content={content.metadesc}/>
                <meta property="og:title" content={content.metatitle}/>
                <meta property="og:description" content={content.metadesc}/>
                <title>{content.metatitle}</title>
            </Helmet>
            <SiteHeader
                title={content.title}
                eyebrow={content.eyebrow}
                bgimage={`${content.bgimage}`}
            />
            <main className="container pt-lg-5 mt-lg-5">
                <div className="row position-relative">
                    <div className="col-lg-4 offset-xl-1 text-start">
                        <h3 className="text-info fw-bold fs-4">
                            Neues Inkasso-Portal
                        </h3>
                        <p class="text-secondary fw-light mb-3">Die Benutzerdaten erhalten Sie per Mail bei der Übergabe eines Inkasso-Falles. Nicht passiert? <a href="/kontakt" class="text-info">Kontaktieren</a>  Sie uns.</p>
                        <a href="https://login.inkasso-partner.ch/IKLU/" target="_blank"
                            className="btn btn-outline-info border-info-lighter fw-bold text-secondary border-2 rounded-3"
                            >Login neues webINKASSO
                        </a>

                    </div>
                    <div className="col-lg-4 offset-lg-1 text-start my-3 my-lg-0">
                        <h3 className="text-info fw-bold fs-4">
                            Altes Inkasso-Portal
                        </h3>
                        <p className="text-secondary fw-light mb-3">
                            Altes Inkasso-Portal – Informationen sind aktuell bis 29. April. <br/>
                            <a
                                href="https://creditreform.bwise.ch/login/" className="text-info">Hier geht es zum alten
                                Login.</a>
                        </p>

                    </div>
                </div>
                <div className="row position-relative">
                <div className="col-lg-7 offset-xl-1 text-start">
                        <h2 className="text-secondary fw-bold my-3 my-lg-5 pt-lg-5">Vorteile neues webINKASSO</h2>

                        <LoginTabs/>

			<p className="text-secondary fw-light mb-3">
    Für eine Live-Demonstration aller Funktionen sowie
weitere Informationen kontaktieren Sie Ihren

Kundenbetreuer oder schreiben Sie uns direkt per
 <a href="mailto:inkasso@luzern.creditreform.ch" className="text-info ms-1">Mail
  </a>
.
            </p>
                    </div>
                </div>
		<div className="row position-relative">
			 <div className="col-lg-11 offset-xl-1 text-start">
				 <h2 className="text-secondary fw-bold my-3 my-lg-5 pt-lg-5">webINKASSO – umfassendes Inkassoportal</h2>
				<img className="w-100 img-fluid" src={content.image_url}/>
			</div>
		</div>
            </main>
        </div>
);
}

export default LoginPage;
