import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function Blog() {
  const slider = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});
  
  const itemsPerPage = 6; // 3 slides per page * 2 rows = 6 items per page

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const locale = localStorage.getItem("cf_language") || "de-CH";
      const res = await fetch(`${process.env.REACT_APP_STRAPI_URL}/api/aktuells?locale=${locale}&pagination[page]=${page}&pagination[pageSize]=${itemsPerPage * 2}&sort=createdAt:desc`);
      const json = await res.json();
      setData(prevData => {
       const newData = [...prevData, ...json.data];
       // Ensure no duplicate entries based on the id
       return newData.filter((item, index, self) => self.findIndex(t => t.id === item.id) === index);
      });
      setMeta(json.meta);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page);
  }, [page]);

  if (loading && data.length === 0) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    rows: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          infinite: true,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        },
      },
    ],
  };

  const handleNext = () => {
    if (page < meta.pagination.pageCount) {
      setPage((prevPage) => prevPage + 1);
    }
    slider.current.slickNext();
  };

  const handlePrev = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
    slider.current.slickPrev();
  };

  return (
    <div className="row">
      <Slider ref={slider} {...settings} className="col-lg-11">
        {data.map((content) => (
          <div key={content.id} className="px-3">
            <div className="bg-white rounded-4 shadow mb-5 px-4 pt-4 pb-3 my-4">
              <h6 className="text-info fw-bold">{new Date(content.attributes.createdAt).toLocaleDateString('de-DE', { year: 'numeric', month: 'long' })}</h6>
              <h4 className="text-secondary fw-bold">{content.attributes.title}</h4>
              <p className="text-info fw-light mt-n-7px">{content.attributes.subtitle}</p>
              <p className="text-secondary fw-light">{content.attributes.description}</p>
              {content.attributes.details && (
                <span>
                  <Link to={`/blog/${content.attributes.slug}`} className="text-info fw-light">mehr lesen</Link>{" "}
                  <span className="text-info fw-light">&#8250;</span>
                </span>
              )}
            </div>
          </div>
        ))}
      </Slider>
      <div className="col-lg-1 d-flex align-items-center">
        <div className="d-flex d-lg-block px-lg-0 px-4 justify-content-between w-100">
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselBlog"
            data-bs-slide="prev"
            onClick={handlePrev}
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselBlog"
            data-bs-slide="next"
            onClick={handleNext}
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </div>
  );
}
