import { BrowserRouter as Router, Route, Redirect, Switch, useSearchParams } from "react-router-dom";

import "./styles/style.scss";

// page and layout imports
import SiteFooter from "./components/SiteFooter";
import BlogDetails from "./pages/BlogDetails";
import Page from "./pages/Page";
import Aktuell from "./pages/Aktuell";
import Faq from "./pages/Faq";
import Inkasso from "./pages/Inkasso";
import Login from "./pages/Login";

function App() {

  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }
  let locale = getQueryVariable('locale');

  if (typeof locale !== 'undefined') {
    localStorage.setItem("cf_language", locale);
  }

  // var locale = null;

  // if (locale == 'en'){
  //  localStorage.setItem("cf_language", "en");
  // }else{
  //  localStorage.setItem("cf_language", "de-CH");
  // }

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/home"> <Redirect to="/" /></Route>
          <Route exact path="/">
            <Page alias="index" />
          </Route>
          <Route path="/aktuell">
            <Aktuell />
          </Route>
          <Route path="/bonitat">
            <Page alias="bonitat" />
          </Route>
          <Route path="/datenschutzerklarung">
            <Page alias="datenschutzerklarung" />
          </Route>
          <Route path="/downloads">
            <Page alias="download" />
          </Route>
          <Route path="/faq">
            <Faq />
          </Route>
          <Route path="/impressum">
            <Page alias="impressum" />
          </Route>
          <Route path="/inkasso">
            <Inkasso />
          </Route>
          <Route path="/inkassoformular">
            <Page alias="inkassoformular" />
          </Route>
          <Route path="/jobs">
            <Page alias="job" />
          </Route>
          <Route path="/kontakt">
            <Page alias="kontakt" />
          </Route>
          <Route path="/privatkonkurse-nehmen-weiter-zu">
            <Page alias="privatkonkurseNehmenWeiterZu" />
          </Route>
          <Route path="/thankyou">
            <Page alias="thankyou" />
          </Route>
          <Route path="/uber-uns">
            <Page alias="uber" />
          </Route>
          <Route path="/verlustscheine">
            <Page alias="verlustscheine" />
          </Route>
          <Route path="/thankyou">
            <Page alias="thankyou" />
          </Route>
          <Route path="/blog/:slug">
            <BlogDetails />
          </Route>
          <Route exact strict path="/login">
            <Login />
          </Route>
        </Switch>
        <SiteFooter />
      </div>
    </Router>
  );
}

export default App;
